<template>
  <div>
    <h1>Contact messages</h1>
    <el-table
      :data="items.filter(item => !search
                                  || item.title.toLowerCase().includes(search.toLowerCase())
                                  || item.user.email.toLowerCase().includes(search.toLowerCase())
                          )"
      v-loading="loading"
      style="width: 100%"
      @sort-change="sortTrigger"
    >
      <el-table-column sortable="custom" label="first_name" prop="first_name" />
      <el-table-column :sortable="false" label="last_name name" prop="last_name" />
      <el-table-column sortable="custom" label="email" prop="email" />
      <el-table-column sortable="custom" label="message" prop="message" />
      <el-table-column sortable="custom" label="created_at" prop="created_at" />
    </el-table>
    <div class="demo-pagination-block">
      <!-- eslint-disable -->
      <el-pagination
        v-model:currentPage="page"
        :page-sizes="[10, 50, 100, 200]"
        :page-size="perPage"
        layout="sizes, prev, pager, next, total"
        :total="total"
        style="margin-top: 20px"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      >
      </el-pagination>
      <!-- eslint-enable -->
    </div>
    <el-dialog v-model="dialogFormVisible" title="Edit">
      <template #default>
        <el-form :model="itemBeingEdited">
          <el-form-item label="Name" :label-width="formLabelWidth">
            <el-input v-model="itemBeingEdited.name"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">
            Cancel
          </el-button>
          <el-button type="primary" @click="dialogFormVisible = false">
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { fetchContactMessages, deleteWorkout } from "@/services"

export default {
  name: 'ContactMessages',
   data() {
    return {
      formLabelWidth: 150,
      dialogFormVisible: false,
      perPage: 10,
      sortDirection: false,
      page: 1,
      total: 0,
      loading: true,
      column: "created_at",
      order: "descending",
      items: [],
      search: '',
      itemBeingEdited: null
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    sortTrigger(event) {
      this.order = event.order
      this.column = event.prop
      this.getItems()
    },
    async getItems() {
      this.loading = true
      let data = {
        perPage: this.perPage,
        page: this.page,
        column: this.column,
        order: this.order === "ascending" ? "ASC" : "DESC"
      }
      await fetchContactMessages(data).then(response => {
        this.items = response.data.data.data
        this.total = response.data.data.total
        this.loading = false
      })
    },
    handleEdit(index, row) {
      this.itemBeingEdited = Object.assign({}, row)
      this.dialogFormVisible = true
    },
    handleDelete(index, row) {
      this.itemBeingEdited = Object.assign({}, row)
      this.dialogDelete = true
    },
    async deleteItem() {
      this.savingInProgress = true
      await deleteWorkout(this.itemBeingEdited).then(() => {
        this.savingInProgress = false
        this.dialogDelete = false
        this.getItems()
      }).catch(error => {
        console.log('error', error)
        this.savingInProgress = false
      })
    },
    handleSizeChange($event) {
      this.perPage = $event
      this.getItems()
    },
    handlePageChange() {
      this.getItems()
    }
  },
}
</script>
<style scoped>
.item-breakdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 45%;
}
.item-breakdown-title {
  width: 100px;
  padding-right: 5px;
}
</style>