<template>
  <div>
      <h1>Blogs</h1>
      <el-button class="add-new-item-button" v-on:click="addNew()">Add new</el-button>
    <el-table
      :data="items.filter(item => !search
                                  || item.title.toLowerCase().includes(search.toLowerCase())
                                  || item.user.email.toLowerCase().includes(search.toLowerCase())
                          )"
      v-loading="loading"
      style="width: 100%"
      @sort-change="sortTrigger"
    >
      <el-table-column sortable="custom" label="Title" prop="title" />
      <el-table-column sortable="custom" label="Slug" prop="slug" />
      <el-table-column width="250%" align="right">
        <template #header>
          <el-input v-model="search" size="mini" placeholder="Type to search" />
        </template>
        <template #default="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">
            Edit
          </el-button>
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
          >
            Delete
          </el-button>
        </template>
      </el-table-column>
      <!-- <el-table-column :sortable="false" label="last_name name" prop="last_name" /> -->
    </el-table>
    <div class="demo-pagination-block">
      <!-- eslint-disable -->
      <el-pagination
        v-model:currentPage="page"
        :page-sizes="[10, 50, 100, 200]"
        :page-size="perPage"
        layout="sizes, prev, pager, next, total"
        :total="total"
        style="margin-top: 20px"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
      >
      </el-pagination>
      <!-- eslint-enable -->
    </div>
    <el-dialog width="90%" v-model="dialogFormVisible" :retain-focus="false" :title="editingItem ? 'Edit' : 'Add new'">
      <template #default>
        <el-form :model="itemBeingEdited">
          <el-form-item label="Title" :label-width="formLabelWidth">
            <el-input v-model="itemBeingEdited.title"></el-input>
          </el-form-item>
          <el-form-item label="Slug" :label-width="formLabelWidth">
            <el-input v-model="itemBeingEdited.slug"></el-input>
          </el-form-item>
          <!-- <v-file-input
            accept="image/jpg, image/jpeg"
            placeholder="Pick an image"
            v-model="itemBeingEdited.image"
            label="Image"
            class="mt-4"
            :input="loadImage()"
            prepend-icon="mdi-paperclip"
            outlined
            :show-size="1000"
          >
          </v-file-input> -->
          <el-form-item label="Image" :label-width="formLabelWidth">
            <el-upload
              class="avatar-uploader"
              action="https://mytempo.club/api/image-upload"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="itemBeingEdited.image" width="300" :src="itemBeingEdited.image" class="avatar" alt="blog-post-cover-image"/>
              <el-icon v-else class="avatar-uploader-icon">ADD</el-icon>
            </el-upload>
            <el-input v-model="itemBeingEdited.image">
            </el-input>
          </el-form-item>
          <el-form-item label="Description" :label-width="formLabelWidth">
            <editor
                v-if="itemBeingEdited"
                v-model="itemBeingEdited.description"
                api-key="2gqo92dolh4qeux2rvuazjfbdx5s732pbp3kcoimlgzyfkjb"
                :disabled= false
                initial-value=""
                :init="{
                  images_upload_url: `${baseURL}/api/image-upload`,
                  height: 500,
                  width: '100%',
                  menubar: true,
                  document_base_url: `${baseURL}/`,
                  relative_urls: false,
                  remove_script_host: true,
                  plugins: [
                    'advlist','autolink','lists','link','image','charmap','preview','anchor',
                    'searchreplace','visualblocks','code','fullscreen',
                    'insertdatetime','media','table','code','help','wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help'
                }"
              />
                

          </el-form-item>
        </el-form>
      </template>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">
            Cancel
          </el-button>
          <el-button :disabled="savingInProgress" type="primary" @click="save()">
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogDelete" title="Delete">
      <template #default>
        Are you sure you want to delete {{itemBeingEdited.title}}?
      </template>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDelete = false">
            Cancel
          </el-button>
          <el-button :disabled="savingInProgress" type="primary" @click="deleteItem()">
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  fetchBlogs,
  createBlog,
  showBlog,
  updateBlog,
  deleteBlog
} from "@/services"

export default {
  name: 'Blogs',
   data() {
    return {
      imageUrl: '',
      formLabelWidth: 150,
      dialogFormVisible: false,
      perPage: 10,
      sortDirection: false,
      savingInProgress: false,
      dialogDelete: false,
      page: 1,
      total: 0,
      loading: true,
      column: "created_at",
      order: "descending",
      items: [],
      errors: null,
      search: '',
      editingItem: true,
      itemBeingEdited: null,
      blogBannerImage: null
    }
  },
  mounted() {
    this.getItems()
    this.baseURL = window.location.origin
  },
  methods: {
    beforeAvatarUpload (rawFile) {
      console.log('rawFile', rawFile)
      if (rawFile.type !== 'image/jpeg') {
        console.error('Avatar picture must be JPG format!')
        return false
      }
      // else if (rawFile.size / 1024 / 1024 > 2) {
      //   console.error('Avatar picture size can not exceed 2MB!')
      //   return false
      // }
      this.loadImage(rawFile)
      return true
    },
    handleAvatarSuccess (response, uploadFile) {
      console.log('response', response)
      console.log('uploadFile', uploadFile)
      // this.imageUrl.value = URL.createObjectURL(uploadFile.raw)
      this.itemBeingEdited.image = response.location
    },
    loadImage(file) {
      if (file) {
        let reader = new FileReader()
        reader.onloadend = async () => {
          // console.log('reader.result', reader.result)
          this.blogBannerImage = reader.result
        }
        reader.readAsDataURL(file)
      }
    },
    sortTrigger(event) {
      this.order = event.order
      this.column = event.prop
      this.getItems()
    },
    async getItems() {
      this.loading = true
      let data = {
        perPage: this.perPage,
        page: this.page,
        column: this.column,
        order: this.order === "ascending" ? "ASC" : "DESC"
      }
      await fetchBlogs(data).then(response => {
        this.items = response.data.data.data
        this.total = response.data.data.total
        this.loading = false
      })
    },
    async handleEdit(index, row) {
      let data = {
        slug: row.slug,
      }
      await showBlog (data).then(response => {
        this.itemBeingEdited = Object.assign({}, response.data.data)
        this.blogBannerImage = response.data.data.image
        this.dialogFormVisible = true
      })
    },
    handleDelete(index, row) {
      this.itemBeingEdited = Object.assign({}, row)
      this.dialogDelete = true
    },
    addNew() {
      this.editingItem = false
      this.itemBeingEdited = Object.assign({}, this.defaultItem)
      this.dialogFormVisible = true
    },
    save() {
      if (this.editingItem) {
        this.updateBlog()
      } else {
        this.createBlog()
      }
    },
    async createBlog() {
      this.savingInProgress = true
      await createBlog(this.itemBeingEdited).then(() => {
        this.savingInProgress = false
        this.dialogFormVisible = false
        this.getItems()
      }).catch(error => {
        this.savingInProgress = false
        this.errors = error.response.data.message
        console.log('error', error)
      })
    },
    async updateBlog() {
      this.savingInProgress = true
      await updateBlog(this.itemBeingEdited).then(() => {
        this.savingInProgress = false
        this.dialogFormVisible = false
        this.getItems()
      }).catch(error => {
        this.savingInProgress = false
        console.log('error', error)
      })
    },
    async deleteItem() {
      this.savingInProgress = true
      await deleteBlog(this.itemBeingEdited).then(() => {
        this.savingInProgress = false
        this.dialogDelete = false
        this.getItems()
      }).catch(error => {
        console.log('error', error)
        this.savingInProgress = false
      })
    },
    handleSizeChange($event) {
      this.perPage = $event
      this.getItems()
    },
    handlePageChange() {
      this.getItems()
    }
  },
}
</script>
<style scoped>
.add-new-item-button {
  position: absolute;
  right: 30px;
  top: 30px;
  height: 50px;
  background-color: #f56c6c;
  color: white;
  z-index: 1;
}
.item-breakdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 45%;
}
.item-breakdown-title {
  width: 100px;
  padding-right: 5px;
}
</style>